define("tmp-for-all/components/shared/clash-resolution-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    authToken: computed.alias('session.data.authenticated.id'),
    clashResolutionStatusOptions: computed('clashResolutionOptions', function () {
      var options = [];
      var lookups = get(this, 'clashResolutionOptions');
      lookups.filter(function (option) {
        return option.reason !== 'Auto-resolved by myWorksites system';
      }).forEach(function (option) {
        return options.push({
          value: option.id,
          name: option.reason
        });
      });
      return options;
    }),
    autoResolved: computed('thisWorksiteResolution.status', 'clashResolutionOptions', function () {
      var _this = this;
      var options = get(this, 'clashResolutionOptions');
      return !!(options.find(function (option) {
        return option.id === get(_this, 'thisWorksiteResolution.status');
      }).reason === 'Auto-resolved by myWorksites system');
    }),
    resolutionDropDownLabel: 'Resolution',
    selectedClashResolution: computed('thisWorksiteResolution', 'clashResolutionOptions', function () {
      var clashStatus = get(this, 'thisWorksiteResolution.status');
      var options = get(this, 'clashResolutionOptions');
      var option = options.find(function (option) {
        return option.id === clashStatus;
      });
      return {
        value: option.id,
        name: option.reason
      };
    }),
    clashNote: computed('thisWorksiteResolution', function () {
      var clashNote = get(this, 'thisWorksiteResolution.note');
      return clashNote !== null && clashNote !== void 0 ? clashNote : '';
    }),
    clashNoteLength: computed('clashNote', function () {
      var note = get(this, 'clashNote');
      return note.split('').length;
    }),
    filesForUpload: [],
    actions: {
      closeModal: function closeModal() {
        get(this, 'toggleClashModal')();
      },
      saveClashResolution: function saveClashResolution() {
        var _this2 = this;
        var clashId = get(this, 'clashId');
        var clashResolutionId = get(this, 'thisWorksiteResolution.id');
        var status = get(this, 'selectedClashResolution.value');
        var note = get(this, 'clashNote');
        var existingFiles = get(this, 'files');
        var newResolutionFiles = get(this, 'filesForUpload');
        var data = {
          status: status,
          note: note
        };
        var newFiles = newResolutionFiles.map(function (fileWrapper) {
          return get(fileWrapper, 'file');
        });
        Promise.all(existingFiles.map(function (file) {
          return file.save();
        })).then(function () {
          return get(_this2, 'ajax').updateClashResolution(clashId, clashResolutionId, data, newFiles);
        }).catch(function () {
          get(_this2, 'flashMessages').error('An error occurred when publishing resolution');
        }).then(function () {
          get(_this2, 'flashMessages').success('This resolution has been published');
          get(_this2, 'toggleClashModal')();
          get(_this2, 'refreshClashTable')();
        });
      }
    }
  });
  _exports.default = _default;
});